<template>
   <section class="cont infor-mation-box">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>营销云</el-breadcrumb-item>
            <el-breadcrumb-item>运营</el-breadcrumb-item>
            <el-breadcrumb-item to="/activity_list/INFORMATION_FLOW_ADVERTISING">信息流广告</el-breadcrumb-item>
            <el-breadcrumb-item>创建信息流广告</el-breadcrumb-item>
         </el-breadcrumb>
         <el-button class="bg-gradient">保存草稿</el-button>
      </el-row>
      <!-- 返回页头 -->
      <el-page-header @back="goBack" content="创建信息流广告"></el-page-header>
      <el-row class="cont-form-box">
         <!-- 步骤条 -->
         <el-steps :active="active" finish-status="success">
            <el-step title="01.设置活动信息"></el-step>
            <el-step title="02.目标用户"></el-step>
            <el-step title="03.如何触达人群"></el-step>
            <el-step title="04.发送什么内容"></el-step>
            <el-step title="05.预览"></el-step>
         </el-steps>

         <el-row class="content">
            <el-row class="step1-cont" v-if="active === 0">
               <el-row class="input-box">
                  <el-col :span="2">活动名称</el-col>
                  <el-col :span="9" class="m-left-10">
                     <el-input  class="width-300" v-model="eventName"></el-input>
                  </el-col>
               </el-row>
               <el-row class="input-box">
                  <el-col :span="2">活动周期</el-col>
                  <el-col :span="9" class="m-left-10">
                     <el-date-picker
                             class="width-300"

                             v-model="timeCycle"
                             value-format="yyyy-MM-dd hh:mm:ss"
                             type="daterange"
                             :start-placeholder="$t('msg.start_date')"
                             :range-separator="$t('msg.to')"
                             :end-placeholder="$t('msg.end_date')"></el-date-picker>
                  </el-col>
               </el-row>
               <el-row class="input-box">
                  <el-col :span="2">转化跟踪</el-col>
                  <el-col :span="9" class="m-left-10">
                     <el-switch v-model="isConvert" active-value="YES" inactive-value="NO"  active-color="#1489e2" inactive-color="#c5ced8"></el-switch>
                  </el-col>
               </el-row>
               <el-row class="input-box" v-if="isConvert === 'YES'">
                  <el-col :span="2">转化目标</el-col>
                  <el-col :span="9" class="m-left-10">
                     <el-select  class="width-300" v-model="targetId" placeholder="请选择">
                        <el-option v-for="item in targetList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                     </el-select>
                  </el-col>
               </el-row>
               <el-row class="input-box" v-if="isConvert === 'YES'">
                  <el-col :span="2">转化周期</el-col>
                  <el-col :span="9" class="m-left-10">
                     <el-input v-model="days"  class="width-140"></el-input>&nbsp;天内
                  </el-col>
               </el-row>
            </el-row>
            <el-row class="step2-cont" v-if="active === 1">
               <el-col :span="2" class="left">目标用户</el-col>
               <el-col :span="22" class="right">
                  <el-row v-for="(item, index) in targetUsers" :key="index">
                     <el-select  v-model="item.groupId" placeholder="请选择目标用户" class="width-300">
                        <el-option v-for="item in groupList" :key="item.id" :label="item.groupName" :value="item.id">
                        </el-option>
                     </el-select>
                     <span class="icon" @click="removeGroup(index)"><i class="el-icon-circle-close"></i></span>
                  </el-row>
                  <el-row>
                     <el-button class="width-300" icon="el-icon-plus"  @click="appendGroup">添加分组</el-button>
                  </el-row>
               </el-col>
            </el-row>
            <el-row class="step3-cont" v-if="active === 2">
               <el-col :span="2" class="left">推送规则</el-col>
               <el-col :span="22" class="right">
                  <el-row class="radio-box">
                     <el-radio v-model="pushRule" label="ALWAYS_SHOW">活动期间一直显示</el-radio>
                     <!--<el-radio v-model="pushRule" label="USER_EVENT_SHOW">基于用户事件触发显示</el-radio>-->
                  </el-row>
                  <!--<el-row class="selected-box">
                     <span class="s1">当用户做出</span>
                     <el-row class="selected">
                        <el-row class="m-bottom-10">
                           <el-select class="width-434" v-model="triggerId" placeholder="请选择" >
                              <el-option v-for="item in targetList" :key="item.id" :label="item.name" :value="item.id">
                              </el-option>
                           </el-select>
                           <span @click="appendEvent"><i class="el-icon-circle-plus-outline"></i></span>
                        </el-row>
                        <el-row class="m-bottom-15" v-for="(item, index) in eventList" :key="index">
                           <el-select class="width-150" v-model="item.value" placeholder="请选择" >
                              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                              </el-option>
                           </el-select>
                           <el-select class="width-100" v-model="value" placeholder="请选择" >
                              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                              </el-option>
                           </el-select>
                           <el-input class="width-100" ></el-input>
                           <span class="m-left-5" @click="removeEvent(index)"><i class="el-icon-circle-close"></i></span>
                        </el-row>
                     </el-row>
                  </el-row>-->
               </el-col>
            </el-row>
            <el-row class="step4-cont" v-show="active === 3">
               <el-col :span="14" class="left-form">
                  <el-row class="right-cont" style="border: none">
                     <el-row>模板选择</el-row>
                     <el-row class="m-left-10">
                        <el-row class="img-box">
                           <img src="@/assets/advert_template_img.png" alt="">
                        </el-row>
                     </el-row>
                  </el-row>
                  <!--<el-row class="right-cont">
                     <el-row>展示位置</el-row>
                     <el-row class="m-left-10">
                        <el-select class="width-434"  v-model="value" placeholder="请选择">
                           <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                           </el-option>
                        </el-select>
                     </el-row>
                  </el-row>-->
                  <el-row class="right-cont m-top-15" style="border: none">
                     <el-row>文字描述</el-row>
                     <el-row class="m-left-10">
                        <el-input type="textarea" class="width-434" v-model="content"></el-input>
                     </el-row>
                  </el-row>
                  <!--<el-row class="right-cont m-top-15" style="border: none">
                     <el-row>个性化设置</el-row>
                     <el-row class="m-left-10">
                        <el-switch v-model="isConvert" active-value="YES" inactive-value="NO"  active-color="#1489e2" inactive-color="#c5ced8"></el-switch>
                     </el-row>
                  </el-row>-->
                  <el-row class="right-cont m-top-15">
                     <el-row>图片地址</el-row>
                     <el-row class="bg-img width-434 m-left-10">
                        <el-row>
                           <el-input disabled  placeholder="输入填写URL" class="input-with-select">
                              <!--<el-select v-model="select" slot="append" placeholder="请选择">
                                 <el-option label="餐厅名" value="1"></el-option>
                                 <el-option label="订单号" value="2"></el-option>
                                 <el-option label="用户电话" value="3"></el-option>
                              </el-select>-->
                           </el-input>
                        </el-row>
                        <el-row class="text">
                           图片最大宽度为640PX最大高度为420PX（缩放样式以具体设备为准），格式支持JPG、PNG、JPEG、GIF格式，
                           图片大小在2M以内。网址必须以https://或http://开头。</el-row>
                        <el-row class="upload">
                           <el-upload
                                   class="avatar-uploader"
                                   action="#"
                                   :limit="1"
                                   :auto-upload="false"
                                   :show-file-list="true"
                                   list-type="picture-card"
                                   :on-change="changeUpload">
                              <p>上传图片</p>
                           </el-upload>
                           <!--<p style="margin-top: 6px;color: red">请输入以https://或http://开头的网址</p>-->
                        </el-row>
                     </el-row>
                  </el-row>
                  <el-row class="right-cont m-top-15" style="border: none">
                     <el-row>Android 动作</el-row>
                     <el-row class="m-left-10">
                        <el-input class="width-434"  v-model="androidUrl"></el-input>
                     </el-row>
                  </el-row>
                  <el-row class="right-cont m-top-15" style="border: none">
                     <el-row>iOS 动作</el-row>
                     <el-row class="m-left-10">
                        <el-input class="width-434"  v-model="iosUrl"></el-input>
                        <el-row class="ios-text">
                           <p>1.如果使用 DeepLink，可以是任何 URI 地址 (eg. myapp://productdetails/itemabc)</p>
                           <p>2.如果使用 URL，可以是任何 http 或 https(eg. https://www.abc.com)</p>
                        </el-row>
                     </el-row>
                  </el-row>
               </el-col>
               <el-col :span="10" class="right-img">
                  <el-row class="img">
                     <el-row class="dynamic-img">
                        <p>活动详情</p>
                        <img v-if="mediaPath" :src="mediaPath" alt="">
                        <img v-else src="@/assets/1615340109.jpg" alt="">
                     </el-row>
                     <img src="@/assets/create_applet_img.jpg" alt="">
                  </el-row>
               </el-col>
            </el-row>
            <el-row class="step4-cont step5-cont" v-show="active === 4">
               <el-col :span="10" class="right-img">
                  <el-row class="img">
                     <el-row class="dynamic-img">
                        <img v-if="mediaPath" :src="mediaPath" alt="">
                        <img v-else src="@/assets/1615340109.jpg" alt="">
                     </el-row>
                     <img src="@/assets/create_applet_img.jpg" alt="">
                  </el-row>
               </el-col>
               <el-col :span="14" class="right-text">
                  <el-row>活动名称:<span>东山风动石</span></el-row>
                  <el-row>目标用户:<span>所有用户</span></el-row>
                  <el-row>开始时间:<span>2021-03-09 00:00:00</span></el-row>
                  <el-row>结束时间:<span>2021-03-09 23:59:59</span></el-row>
                  <el-row>推送规则:<span>活动期间一直显示</span></el-row>
                  <el-row>插图链接:<span>https://123eguan.cn-bj.ufileos.com/123_7f3ed4e0-b1ad-4390-a27e-37929990c638.jpg</span></el-row>
                  <el-row>消息内容:<span>活动详情</span></el-row>
                  <el-row>
                     <el-button  @click="active --">上一步</el-button>
                     <el-button  type="primary" @click="handleSave">开始</el-button>
                     <el-button >发送到测试设备</el-button>
                  </el-row>
               </el-col>
            </el-row>
            <el-row class="next-btn m-top-15" v-show="active !== 4">
               <el-button  type="primary" v-show="active !== 0" @click="active --">上一步</el-button>
               <el-button  type="primary" class="m-left-30" @click="nextStep">下一步</el-button>
            </el-row>
         </el-row>
      </el-row>
   </section>
</template>

<script>
   import { urlObj } from '@/api/interface'
   import { operate } from  '@/api/interface/smtech'
   import { label} from '@/api/interface/data'
   import { mapState } from 'vuex'
   export default {
      watch: {
         hotelInfo: {
            handler(newVal, oldVal) {
               if (newVal.id !== oldVal.id  && oldVal) {
                  this.hotelId = this.hotelInfo.id
                  this.getGroupList()
               }
            },
            deep: true
         }
      },
      computed: {
         ...mapState(['hotelInfo'])
      },
      data() {
         return {
            active: 0,
            action: '',       // 操作行为
            accountType: '',
            /* 第一步 */
            eventName: '',    // 活动名称
            timeCycle: [],    // 周期时间段
            isConvert: 'NO',  // 转化跟踪
            days: '',         // 转化周期
            targetId: '',     // 转化目标
            targetList: [],   // 转化目标列表
            /* 第二步 */
            targetUsers: [{}],// 转化用户列表
            groupList: [],    // 群组列表
            /* 第三步 */
            pushRule: 'ALWAYS_SHOW',  // 推送规则
            triggerId: '',            //
            eventList: [{}],  //
            /* 第四步 */
            content: '',
            file: null,
            mediaPath: '',
            androidUrl: '',     // 安卓动作
            iosUrl: '',         // IOS动作
         }
      },
      mounted() {
         this.hotelId = this.hotelInfo.id
         this.accountType = JSON.parse(sessionStorage.getItem('userInfo')).accountType
         let companyId = sessionStorage.getItem('userInfo').tenantId || ''
         if (typeof(companyId) !== 'undefined') this.companyId = companyId
         this.action = this.$route.query.act
         this.action === 'edit' && this.setAdvert()
         this.getGroupList()
      },
      beforeDestroy() {
         sessionStorage.removeItem('advertObj')
      },
      methods: {
         // 返回上页
         goBack() {
            this.$router.go(-1)
         },
         // 设置信息流广告编辑信息
         setAdvert(){
            let advertObj = sessionStorage.getItem('activityObj')
            this.id = advertObj.id
            this.eventName = advertObj.eventName
            this.timeCycle.push(advertObj.beginDate, advertObj.endDate)
            this.isConvert = advertObj.isConvert
            this.targetId = advertObj.targetId
            this.targetUsers = advertObj.eventGroupRefList
            this.pushRule = advertObj.pushRule
            this.mediaPath = window.getResourcesHost() + advertObj.eventWechatDTO.mediaPath
         },
         // 处理保存
         handleSave(){
            let groupIds = []
            this.targetUsers.forEach(item => groupIds.push(item.groupId))
            let pageUrl
            pageUrl = this.androidUrl + ',' + this.iosUrl
            let url = operate.createActivity
            let param = new FormData()
            param.append('file', this.file)                // 文件
            param.append('module', 'system')         // 所在模块
            param.append('menu', 'company')          // 所属菜单
            param.append('func', 'logo')             // 图片功能
            param.append('category', 'logo')         // 图片分类
            param.append('isThumb', true)            // 是否开启缩略图
            param.append('hotelId', this.hotelId)
            param.append('companyId', this.companyId)
            param.append('eventName', this.eventName)
            param.append('beginDate', this.timeCycle[0])
            param.append('endDate', this.timeCycle[1])
            param.append('isConvert', this.isConvert)
            param.append('targetId', this.targetId)
            param.append('days', this.days)
            param.append('groupIds', groupIds.join())
            param.append('pushRule', this.pushRule)
            param.append('isWaitSend', 'NO')
            param.append('platform', 'INFORMATION_FLOW_ADVERTISING')
            param.append('state', 'IN_PROGRESS')
            param.append('eventWechatDTO.type', 'ADVERTISEMENT_IMG_POPUP')
            param.append('eventWechatDTO.content', this.content)
            param.append('eventWechatDTO.pageUrl', pageUrl)
            if (this.act === 'edit') {
               param.append('id', this.id)
               url = operate.editActivity
            }
            this.$axios.post(url, param, 'file').then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: '创建成功！',
                     type: 'success'
                  })
                  this.goBack()
               }
            })
         },
         /* 第一步 */
         // 获取转化目标列表
         getTargetList(category){
            const url = urlObj.metadataList
            const param = { category }
            this.$axios.post(url, param).then(res => {
               if (res.success) this.targetList = res.records
            })
         },
         /* 第二步 */
         // 追加转化目标列
         appendGroup(){
            this.targetUsers.push({})
         },
         // 移除转化目标
         removeGroup(index){
            let length = this.targetUsers.length
            if (length > 1) this.targetUsers.splice(index, 1)
         },
         // 获取群组列表
         getGroupList(){
            const url = label.tagGroup
            const param = {
               hotelId: this.hotelId,
               groupSource: 'WECHAT_OFFICIAL',
               limit: 15,
               page: 1,
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.groupList = res.records
               }
            })
         },
         /* 第三步 */
         // 追加事件
         appendEvent(){
            this.eventList.push({})
         },
         // 移除事件
         removeEvent(index){
            this.eventList.splice(index, 1)
         },
         /* 第四步 */
         changeUpload(file){
            this.mediaPath = file.url
            this.file = file.raw
         },
         // 下一步
         nextStep(){
            this.active ++
         },
      }
   }
</script>

<style lang="scss" scoped>

   .content{
      font-size: 13px;
      .step1-cont{
         width: 700px; padding: 30px 0 0 40px;
         .input-box{
            line-height: 28px; margin-bottom: 20px;
         }
      }
      .step2-cont{
         width: 700px; padding: 30px 0 0 40px;
         .left { line-height: 28px }
         .right {
            .el-row {
               margin: 0 0 15px 10px;
               .el-select{ margin-bottom: 0 }
               .icon{ font-size: 18px; color: #999999; cursor: pointer }
            }
         }
      }
      .step3-cont{
         width: 700px; padding: 30px 0 0 40px; margin-bottom: 30px;
         .left{ line-height: 34px }
         .radio-box{
            margin-left: 10px;
            .el-radio{
               padding: 8px 4px; border: solid 1px #e4e4e4;
            }
         }
         .selected-box{
            margin-top: 20px;
            .s1{ float: left; line-height: 28px }
            .selected{
               float: left; margin-left: 5px;
               .el-row{
                  .el-icon-circle-plus-outline{ font-size: 18px; cursor: pointer; color: #999999 }
                  .el-icon-circle-close{ font-size: 16px; cursor: pointer; color: #999999 }
               }
            }
         }
      }
      .step4-cont{
         .left-form{
            font-size: 13px;
            min-width: 500px;
            padding: 20px 5% 0 20px;
            .right-cont > .el-row{ float: left }
            .right-cont > .el-row:nth-child(1) { width: 76px; text-align: right;
            font-size: 13px }
            .right-cont{
               padding-bottom: 20px; border-bottom: 1px solid #dddddd;
               overflow: hidden;
               .bg-img{
                  .text{
                     margin-top: 10px;
                     padding: 10px;
                     font-size: 12px;
                     line-height: 20px;
                     color: #999999;
                     border: solid 1px #dddddd;
                  }
                  .upload{
                     margin-top: 10px;
                     .avatar-uploader{
                        /*/deep/ .el-upload-list{
                           display: none;
                        }*/
                        /deep/ .el-upload-list__item{
                           width: 80px; height: 80px;
                        }
                        /deep/ .el-upload--picture-card{
                           width: 100%!important;
                           height: 28px!important;
                           p{
                              font-size: 12px;
                              width: 100%;
                              position: absolute;
                              line-height: 28px;
                           }
                           .avatar-uploader .el-upload i{
                              height: 28px!important;
                              line-height: 28px!important;
                           }
                        }
                     }
                  }
                  .upload-btn{
                     margin-top: 10px;
                     p {
                        margin-top: 8px;
                        font-size: 12px;
                        color: red;
                     }
                     .el-button{
                        width: 100%;
                     }
                  }
               }
               .ios-text{
                  border: solid 1px #f7f7f8; padding: 8px; font-size: 12px;
                  color: #999999; line-height: 30px;
               }
            }
            .img-box{
               width: 160px; height: 100px; border: solid 1px #dddddd;
               img { width: 80%; margin: 8px auto 0; display: block }
            }
         }
         .right-img{
            padding: 40px 0; height: auto; background-color: #f8f5ff;
            .img{
               width: 254px; margin: 0 auto; position: relative;
               .dynamic-img{
                  width: 120%; top: 30%; left: -10%; padding: 15px 10px;
                  position: absolute; height: 210px; background-color: #ffffff;
                  box-shadow: 0 0 5px #eeeeee; overflow: hidden;
                  img{ width: 100% }
                  p { margin-bottom: 10px }
               }
            }
         }
      }
      .step5-cont{
         .right-text{
            padding: 6% 10%; line-height: 40px; color: #999999;
            span{ color: #333333; margin-left: 10px }
         }
      }
      .next-btn { margin: 0 0 40px 40px }
   }
   @media screen and (max-width: 1366px) {
     .main {
       width: 1500px;
     }
   }
</style>
